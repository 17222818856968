import { EditOutlined, InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Modal, Space, Switch, Table, Tooltip } from "antd";
import useVendorStore from "stores/vendorStore";


const VendorTable = ({ toggleEditMode, toggleStatus,currentUser}) => {


const showMoreData=(vendor)=>{

  Modal.info({
    title: 'More',
    content: (

      <div>
     
      <Divider>Notes</Divider>
      {vendor.notes}
      <Divider></Divider>

      <Space size={43} >
          <span style={{ fontWeight: 'bolder' }} >Alternate Phone</span>: <span style={{ float: 'left' }} >   {vendor.alternate_phone} </span> 
       </Space>
       <br></br>
       
        <Space size={27} >

          <span style={{ fontWeight: 'bolder', }} >GSTIN</span>:  <span style={{float:'left'}} > {vendor.gstin}  </span> 
        </Space>
        <br></br>

        <Space size={40}  >
          <span style={{ fontWeight: 'bolder' }} >PAN</span>: <span style={{ float: 'right' }} >  {vendor.pan}  </span> 
      </Space>
    
      

      </div>


    ),
    onOk() { },
  });

}



  const vendors = useVendorStore((state) => state.vendors);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "0",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "2",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
     
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "3",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {

        console.log({ record });

        return record?.phone?.toLowerCase().includes(value.toLowerCase());

      },


    },
    {
      title: "State",
      dataIndex: "state",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {

        console.log({ record });

        return record?.state?.toLowerCase().includes(value.toLowerCase());

      },
      key: "3",


    },
    {
      title: "City",
      dataIndex: "city",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {

        console.log({ record });

        return record?.city?.toLowerCase().includes(value.toLowerCase());

      },
      key: "4",
  

    },
    {
      title: "Address",
      dataIndex: "address",
      key: "4",
      width:'30%'


    },
   

    {
      title: "Edit",
      key: "2",
      render: (_, categories) => (
        <Space key={categories?._id} size="middle">




          <Tooltip
            title={"Edit Vendor"}
          >



            <EditOutlined
              hidden={!currentUser.privileges.includes('vendor_management')}
              onClick={() => toggleEditMode(categories?._id)}
              key={categories?._id}
            />

          </Tooltip>

    
        </Space>
      ),
      
      
    },

    {
      title: "More",
      key: "8",
      render: (_, vendor) => (



          <Tooltip
            title={"Vendor Details"}
          >



          <InfoCircleOutlined onClick={() => showMoreData(vendor)} />
          </Tooltip>
       
      ),
      align: "center",
      width: "5%",
    },
    {
      title: "Status",
      key: "9",
      render: (_, vendor) => (

        <Switch
          hidden={!currentUser.privileges.includes('vendor_management')}
          key={vendor._id}
          checked={vendor.is_active}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          title="Status"
          onChange={() => toggleStatus(vendor)}
        />

      ),
      align: "center",
      width: "5%",

      filters: [
        { text: "Active", value:true   },
        { text: "Inactive ", value: false},
      ],

      onFilter: (value, vendor) => vendor.is_active==value,
    },
    
  ];




  return (
    <>
      <Table
       rowClassName={'rowFontColor'}
        columns={columns}
        dataSource={vendors}
        pagination={false}
        rowKey={'_id'}
      />
    </>
  );
};

export default VendorTable;
