import { Button, Form, Input, Select, Space, Switch } from "antd";

import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import findRegion from "utils/constants/state&cities";

const VendorForm = ({
  submitHandler,
  editData,
  submitLoading,
  confirmEditHandler,
}) => {
  const [form] = Form.useForm();
  const { changeCity, optionsList } = findRegion();
  const { Option } = Select;
  const [cityList, setCityList] = useState("");

  const onChange = (value) => {
    //setting city values
    console.log(`${value}`);
    var newCity = changeCity(value);
    console.log(newCity.optionsList);

    const CityList = newCity.optionsList.map((city, index) => {
      return {
        label: city,
        value: city,
        key: index,
      };
    });

    setCityList(CityList);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const onFinish = (values) => {
    values.country = "india";
    console.log({ values });

    if (editData) {
      values.vendor_id = editData._id;

      confirmEditHandler(values, form);
    } else {
      submitHandler(values, form);
    }
  };

  useEffect(() => {
    const newCity = [
      "Bagalkot",
      "Bangalore Rural",
      "Bangalore Urban",
      "Belgaum",
      "Bellary",
      "Bidar",
      "Vijayapura",
      "Chamarajanagar",
      "Chikkaballapur",
      "Chikkamagaluru",
      "Chitradurga",
      "Dakshina Kannada",
      "Davanagere",
      "Dharwad",
      "Gadag",
      "Gulbarga",
      "Hassan",
      "Haveri",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysore",
      "Raichur",
      "Ramanagara",
      "Shimoga",
      "Tumkur",
      "Udupi",
      "Uttara Kannada",
      "Yadgir",
    ];

    const CityList = newCity.map((city, index) => {
      return {
        label: city,
        value: city,
        key: index,
      };
    });

    setCityList(CityList);
  }, []);

  useEffect(() => {
    //setting edit values

    form.setFieldsValue({
      name: editData?.name || "",
      email: editData.email || "",
      phone: editData.phone || "",
      gstin: editData?.gstin || "",
      alternate_phone: editData?.alternate_phone || "",
      is_active:
        editData.is_active === undefined
          ? true
          : editData.is_active === false
          ? false
          : true,
      pan: editData?.pan || "",
      address: editData?.address || "",
      notes: editData.notes || "",
      state: editData.state || "Karnataka",
      city: editData.city || "",
    });

  }, [editData]);

  return (
    <Form
      form={form}
      key={editData?._id}
      id="vendorForm"
      name="vendorForm"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Space size={0} align="end" direction="horizontal">
        <Form.Item
          style={{ marginLeft: "3rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="name"
          label="Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter The Name" style={{ width: 200 }} />
        </Form.Item>

        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          name="email"
          label="Email"
          rules={[{ required: true }]}
        >
          <Input
            type={"email"}
            placeholder="Enter The Email"
            style={{ width: 200 }}
          />
        </Form.Item>

        <Form.Item
          style={{ marginLeft: "-1rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="phone"
          label={"Phone"}
          rules={[
            {
              required: true,
              message: "Missing Phone Number",
            },

            {
              len: 10,
              message: "Phone number should be 10 digits",
            },
          ]}
        >
          <Input
            type={"number"}
            maxLength={10}
            minLength={10}
            placeholder="Phone"
          />
        </Form.Item>

        <Form.Item
          style={{ marginLeft: "2em" }}
          labelCol={{ span: 24, offset: 0 }}
          name="alternate_phone"
          label={"Alternate Phone"}
        >
          <Input
            type={"number"}
            maxLength={10}
            minLength={10}
            placeholder="Alternate Phone"
          />
        </Form.Item>
      </Space>

      <Space size={0} align="end" direction="horizontal">
        <Form.Item
          style={{ marginLeft: "3rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="gstin"
          label="GSTIN"
          rules={[
            { required: true },

            {
              len: 15,
              message: "GSTIN number should be 15 digits",
            },
          ]}
        >
          <Input placeholder=" GTSIN" style={{ width: 200 }} />
        </Form.Item>

        <Form.Item
          style={{ marginLeft: "0rem" }}
          labelCol={{ span: 24, offset: 0 }}
          name="pan"
          label={"PAN"}
          rules={[
            {
              required: true,
              message: "Missing PAN number",
            },

            {
              len: 10,
              message: "PAN number should be 10 digits",
            },
          ]}
        >
          <Input
            type={"text"}
            maxLength={10}
            minLength={10}
            placeholder="PAN"
          />
        </Form.Item>

        <Form.Item
          style={{ marginLeft: "3rem", width: "200px" }}
          labelCol={{ span: 24, offset: 0 }}
          name="state"
          label="State"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Select a State"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="Andhra Pradesh">Andhra Pradesh</Option>
            <Option value="Andaman and Nicobar Islands">
              Andaman and Nicobar Islands
            </Option>
            <Option value="Arunachal Pradesh">Arunachal Pradesh</Option>
            <Option value="Assam">Assam</Option>
            <Option value="Bihar">Bihar</Option>
            <Option value="Chandigarh">Chandigarh</Option>
            <Option value="Chhattisgarh">Chhattisgarhv</Option>
            <Option value="Dadar and Nagar Haveli">
              Dadar and Nagar Haveli
            </Option>
            <Option value="Daman and Diu">Daman and Diu</Option>
            <Option value="Delhi">Delhi</Option>
            <Option value="Lakshadweep">Lakshadweep</Option>
            <Option value="Puducherry">Puducherry</Option>
            <Option value="Goa">Goa</Option>
            <Option value="Gujarat">Gujarat</Option>
            <Option value="Haryana">Haryana</Option>
            <Option value="Himachal Pradesh">Himachal Pradesh</Option>
            <Option value="Jammu and Kashmir">Jammu and Kashmir</Option>
            <Option value="Jharkhand">Jharkhand</Option>
            <Option value="Karnataka">Karnataka</Option>
            <Option value="Kerala">Kerala</Option>
            <Option value="Madhya Pradesh">Madhya Pradesh</Option>
            <Option value="Maharashtra">Maharashtra</Option>
            <Option value="Manipur">Manipur</Option>
            <Option value="Meghalaya">Meghalaya</Option>
            <Option value="Mizoram">Mizoram</Option>
            <Option value="Nagaland">Nagaland</Option>
            <Option value="Odisha">Odisha</Option>
            <Option value="Punjab">Punjab</Option>
            <Option value="Rajasthan">Rajasthan</Option>
            <Option value="Sikkim">Sikkim</Option>
            <Option value="Tamil Nadu">Tamil Nadu</Option>
            <Option value="Telangana">Telangana</Option>
            <Option value="Tripura">Tripura</Option>
            <Option value="Uttar Pradesh">Uttar Pradesh</Option>
            <Option value="Uttarakhand">Uttarakhand</Option>
            <Option value="West Bengal">West Bengal</Option>
          </Select>
        </Form.Item>

        <Form.Item
          style={{ marginLeft: "2rem", width: "200px" }}
          labelCol={{ span: 24, offset: 0 }}
          name="city"
          label="City"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder="Select a City"
            optionFilterProp="children"
            onSearch={onSearch}
            options={cityList}
            filterOption={(input, option) =>
              option?.value?.toLowerCase().includes(input?.toLowerCase())
            }
          ></Select>
        </Form.Item>
      </Space>

      <Form.Item
        style={{ marginLeft: "3rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="address"
        label="Address"
        rules={[{ required: true }]}
      >
        <Input
          placeholder="Enter The Address"
          style={{ width: 855 }}
          type={"text"}
        />
      </Form.Item>

      <Form.Item
        style={{ marginLeft: "3rem" }}
        labelCol={{ span: 24, offset: 0 }}
        name="notes"
        label="Note"
    
      >
        <TextArea placeholder="Notes" style={{ width: 855 }} type={"text"} />
      </Form.Item>

      <Form.Item
        style={{ marginLeft: "3rem" }}
        valuePropName={"checked"}
        name="is_active"
      >
        <Switch
          style={{ width: 100 }}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />

        
      </Form.Item>

      {editData ? (
        <Button
          className="submitButton"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={submitLoading}
        >
          Confirm
        </Button>
      ) : (
        <Button
          className="submitButton"
          key="submit"
          type="primary"
          htmlType="submit"
          loading={submitLoading}
        >
          Submit
        </Button>
      )}
    </Form>
  );
};

export default VendorForm;
