import {
  CheckOutlined,
  CloseCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileSearchOutlined,
  RetweetOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Popover,
  Space,
  Table,
  Timeline,

  Tooltip
} from "antd";
import { useEffect, useState } from "react";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import humanize from "utils/formate-status";

import useInvoiceStore from "stores/invoiceStore";
import useUserStore from "stores/teamStore";
import TeamApi from "utils/composable/teamApi";
const InvoiceTable = ({
  handleCancel,
  confirmEditHandler,
  editHandler,
  showSpecs,
  selectedproducts,
  setSelectedProducts,
  products,
  state,
  currentClient,
  setCurrentClient,
  updateHandler,
  receiptLoading,
  setButtonLoading,
  buttonLoading,
  tableLoading,
  currentUser,
  setCurrentSearchClient,
  setImportedInvoices,
  selectedRows,
  setSelectedRows

}) => {

  const invoices = useInvoiceStore((state) => state.invoices);
  const [currentButtonId, setCurrentButtonId] = useState(false);

  // const [rowSelection, setRowSelection] = useState({

  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedRows(selectedRows);
      

  //   },
  // });









  const { getUsers } = TeamApi();
  const users = useUserStore((state) => state.users);

  const navigate = useNavigate();

  const content = (data) => {


    return (
      <div>
        <p>{`Item Price :  ₹ ${data?.price.item_total.toFixed(2) }`}</p>
        <p>{`Qty :  ₹ ${data?.price.qty.toFixed(2) }`}</p>
        <p>{`Tax :  ₹ ${data?.price.tax_amount.toFixed(2) }`}</p>
    
      </div>
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  const logTimeline = (data) => {
    Modal.info({
      width: "50rem",

      title: "Payment Logs",
      content: (
        <Timeline mode={"left"}>
          {data.logs.map((log) => {
            console.log(log, "logs");

            var color;
            var localTime = new Date(log.date);
            var time = localTime.toString();

            if (log.vendor_transferred_date) {
              color = "#007FFF";
            } else if (log.vendor_transferred_date) {
              color = "green";
            } else {
              color = "#5D76A9";
            }

            function formatTimeShow(h_24) {
              var h = h_24 % 12;
              if (h === 0) h = 12;
              return (
                h + `: ${time.substr(19, 2)}` + (h_24 < 12 ? " am" : " pm")
              );
            }

            var exactTime = formatTimeShow(parseFloat(time.substr(15, 10)));
            var text;
            var suffix = "";

            text = "by  " + users.find((user) => user._id == log.by).name;

            return (
              <Timeline.Item
                style={{ fontSize: "15px" }}
                dot={
                  log.type == "created" ? (
                    <CheckOutlined />
                  ) : log.type == "cancelled" ? (
                    "X"
                  ) : (
                    ""
                  )
                }
                color={color}
              >
                {suffix} {log?.text} {text}{" "}
                <span style={{ fontWeight: "200" }}> on</span>{" "}
                <span style={{ fontWeight: "lighter", fontSize: "12px" }}>
                  {" "}
                  {time.substr(3, 12)}, {exactTime}{" "}
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ),
      onOk() {},
    });
  };







  //Fuzzy filter function


  const [filteredData, setFilteredData] = useState(false)
  const [currentQuery, setCurrentQuery] = useState('')

  const fuzzyFilter = (query) => {

    console.log(query);
    setCurrentQuery(query)

    var get_bigrams, j, len, obj, relevance, results, string_similarity;


    get_bigrams = function (string) {
      console.log({ string });
      var i, j, ref, s, v;
      s = string.toLowerCase();
      v = new Array(s.length - 1);
      for (i = j = 0, ref = v.length; j <= ref; i = j += 1) {
        v[i] = s.slice(i, i + 2);
      }
      return v;
    };

    string_similarity = function (str1, str2) {
      console.log({ str1, str2 });
      var hit_count, j, k, len, len1, pairs1, pairs2, union, x, y;
      if (str1.length > 0 && str2.length > 0) {
        pairs1 = get_bigrams(str1);
        pairs2 = get_bigrams(str2);
        union = pairs1.length + pairs2.length;
        hit_count = 0;
        for (j = 0, len = pairs1.length; j < len; j++) {
          x = pairs1[j];
          for (k = 0, len1 = pairs2.length; k < len1; k++) {
            y = pairs2[k];
            if (x === y) {
              hit_count++;
            }
          }
        }
        if (hit_count > 0) {
          return (2.0 * hit_count) / union;
        }
      }
      return 0.0;
    };


    if (query.length > 2) {
    results = [];

    for (j = 0, len = invoices.length; j < len; j++) {
      var payment = invoices[j];
      relevance = string_similarity(query, payment?.client?.name || "");
      obj = {
        ...payment,
        relevance: relevance
      };
      results.push(obj);
    }


    var sortedResults = results.filter((result) => result.relevance > 0.2)

    sortedResults = sortedResults.sort((a, b) => a.relevance - b.relevance).reverse()

    console.log({ sortedResults });

    // invoices = [...sortedResults]

    setFilteredData(sortedResults)
    console.log(results);

    if (sortedResults.length)
    { setCurrentSearchClient(sortedResults[0].client)
    }else{


      setCurrentSearchClient(0)
    }
    }
  }







  useEffect(() => {

    if (filteredData.length) {

      fuzzyFilter(currentQuery)

    }

  }, [invoices])

  const columns = [


    {
      title: 'Select',
      dataIndex: 'select',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedRows.some((row) => row?._id === record?._id)}
          onChange={() => handleRowSelect(record)}
          disabled={record.status === 'cancelled' || record.status === 'invoice_created' || record.status === 'paid'}
        />
      ),
    },
    // Other columns...


    {
      title: "Invoice No",
      dataIndex: "invoice_number",
      width: "6%",
      key: "6",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              style={{ margin: "1rem", width: "25rem" }}
              autoFocus
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target?.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              placeholder="Search"
            ></Input>
            <Button
              style={{ margin: "1rem" }}
              type="primary"
              onClick={() => {
                confirm();
              }}
            >
              Search
            </Button>
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => {
                clearFilters({ confirm: true });
                setSelectedKeys("");
                confirm();
              }}
            >
              Reset
            </Button>
          </>
        );
      },

      filterIcon: () => {
        return <SearchOutlined />;
      },

      onFilter: (value, record) => {
        console.log({ record }, "record");

        return record?.invoice_number.toString()?.includes(value.toString());
      },
    },

    {
      title: "Client",
      key: "2",
      defaultSortOrder: 'descend',
      sorter: (a, b) => a?.client?.name.localeCompare(b?.client?.name),
      render: (_, record) => {
        const clientName = record.client?.name || "";
        const landline = record.client?.landline || "";
        const contacts = record.client?.contacts || [];
        const contactNumbers = contacts.map(contact => contact.phone).filter(Boolean).join(", ");
        const allNumbers = [landline, contactNumbers].filter(Boolean).join(", ");
        
        return (
          <div>
            <span>{clientName}</span>
            <br />
            <span>{allNumbers}</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      key: "15",
      render: ( record) => {

       
        if (record.status == "paid" ) {
          return (
            <Button
              onClick={() =>
                navigate("/invoice/" + record?._id, {
                  state: {
                    payment: record,
                  },
                })
              }
            >
             Paid
            </Button>
          );
        } else {
          return humanize(record.status) ;
        }
      },

      filters: [
        { text: "Paid", value: "paid" },
        { text: "Pending", value: "pending" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Partially Paid", value: "partially_paid" },
      ],

      onFilter: (value, record) => record.status.includes(value),
    },

    {
      title: "Date",
      dataIndex: "date",
      render: (_, record) => moment(_).format("DD-MM-YYYY"),
      key: "date",
    },
/*
    {
      title: "Description",
      dataIndex: "date",
      render: (_, record) => record.description,
      key: "3",
    },
*/
    {
      title: "Amount",
      render: (record) => {
        return (
          <>
            {record.price.amount.toFixed(2)}
            <Popover
              style={{ padding: "1rem" }}
              content={() => content(record)}
              title="Payment Details
                "
            >
              <ExclamationCircleOutlined
                style={{ paddingLeft: "0.5rem" }}
              ></ExclamationCircleOutlined>
            </Popover>
          </>
        );
      },
      key: "2",
      
    },
    {
      title: "Amount Paid",
      dataIndex: "date",
      render: (_, record) => record.paid_amount.toFixed(2),
      key: "1",
    },

    {
      title: "Adjusted Amount",
      dataIndex: "adjusted_amount",
      render: (_, record) => record.adjusted_amount.toFixed(2),
      key: "2",
    },
    {
      title: "Balance",
      dataIndex: "date",
      render: (_, record) => record.balance_amount.toFixed(2),
      key: "3",
    },

    {
      title: "Action",

      key: "4",
      render: (invoice) => {
        if (invoice.status == "pending") {
          return (
            <Space key={invoice?._id} size="large">
              <Tooltip title={" Edit Invoice"}>
                <Button
                  hidden={!currentUser.user.privileges.includes('payment_management')}
                  icon={<EditOutlined />}
                  key={invoice?._id}
                  onClick={() => editHandler(invoice)}
                />
              </Tooltip>

              <Tooltip title={" Cancel Invoice"}>
                <Button
                  hidden={!currentUser.user.privileges.includes('payment_management')}
                  icon={<CloseCircleOutlined />}
                  loading={buttonLoading && currentButtonId == invoice._id}
                  onClick={() => {
                    Modal.confirm({
                      title: "Confirm",
                      content: "Cancel this Invoice ?",
                      okText: "Yes",
                      cancelText: "Cancel",
                      onOk: () => {
                        updateHandler({
                          cancel: true,
                          invoice_id: invoice._id,
                          invoice_number: invoice.invoice_number,
                        });
                        setButtonLoading(true);
                        setCurrentButtonId(invoice._id);
                      },
                      // onCancel: () => handleCancel(),
                    });
                  }}
                />
              </Tooltip>

              <Tooltip title={"Show Details"}>
                <Button
                  hidden={!currentUser.user.privileges.includes('payment_management')}
                  icon={<FileSearchOutlined />}
                  onClick={() =>
                    navigate("/invoice/" + invoice?._id, {
                      state: {
                        payment: invoice,
                      },
                    })
                  }
                />
              </Tooltip>

              <Tooltip title={"More Details"}>
                <ExclamationCircleOutlined
                  onClick={() => logTimeline(invoice)}
                  style={{ paddingLeft: "0.5rem" }}
                ></ExclamationCircleOutlined>
              </Tooltip>
            </Space>
          );
        } else if (invoice.status == "cancelled") {
          return (
            <Space>
              <Tooltip title={"Restore"}>
                <Button
                  hidden={!currentUser.user.privileges.includes('payment_management')}
                  icon={<RetweetOutlined />}
                  loading={buttonLoading && currentButtonId == invoice._id}
                  onClick={() => {
                    const paymentId = invoice.items.map(
                      (item) => item.payment_id
                    );

                    Modal.confirm({
                      title: "Confirm",
                      content: "Restore this Invoice ?",
                      okText: "Yes",
                      cancelText: "Cancel",
                      onOk: () => {
                        updateHandler({
                          restore: true,
                          restore_payment_ids: paymentId,
                          invoice_id: invoice._id,
                          client: invoice.client._id,
                          invoice_number: invoice.invoice_number,
                        });

                        setButtonLoading(true);
                        setCurrentButtonId(invoice._id);
                      },
                      // onCancel: () => handleCancel(),
                    });
                  }}
                />
              </Tooltip>

              <Tooltip 
                hidden={!currentUser.user.privileges.includes('payment_management')}
              title={"Show Details"}>
                <Button
                  icon={<FileSearchOutlined/>}
                  onClick={() =>
                    navigate("/invoice/" + invoice?._id, {
                      state: {
                        payment: invoice,
                      },
                    })
                  }
                />
              </Tooltip>

              <Tooltip title={" Show Logs"}>
                <ExclamationCircleOutlined
                  onClick={() => logTimeline(invoice)}
                  style={{ paddingLeft: "0.5rem" }}
                ></ExclamationCircleOutlined>
              </Tooltip>
            </Space>
          );
        } else {
          return (
            <Space>
              <Tooltip title={"Show Details"}>
                <FileSearchOutlined
                  hidden={!currentUser.user.privileges.includes('payment_management')}
                  onClick={() =>
                    navigate("/invoice/" + invoice?._id, {
                      state: {
                        payment: invoice,
                      },
                    })
                  }
                />
              </Tooltip>

              <Tooltip title={" Show Logs"}>
                <ExclamationCircleOutlined
                  onClick={() => logTimeline(invoice)}
                  style={{ paddingLeft: "0.5rem" }}
                ></ExclamationCircleOutlined>
              </Tooltip>
            </Space>
          );
        }
      },
    },
  ];

  const handleRowSelect = (record) => {
    const index = selectedRows.findIndex((row) => row._id === record._id);
    const newSelectedRows = [...selectedRows];

    if (index === -1) {
      newSelectedRows.push(record);
    } else {
      newSelectedRows.splice(index, 1);
    }

    setSelectedRows(newSelectedRows);

    console.log({ newSelectedRows });

    const importFormatted=newSelectedRows.map((invoice)=>{

        return{
            client:invoice.client.name,
            amount:0,
          date: moment(invoice.date).format("DD-MM-YYYY") ,
          invoice:invoice.invoice_number.toString()

        }


    })

    setImportedInvoices(importFormatted)
  };

  return (
    <>
      <Table
  
        loading={tableLoading}
        key={invoices?._id}
        rowKey={"_id"}
        columns={columns}
        pagination={true}
        dataSource={filteredData ? filteredData : invoices}
      />
    </>
  );
};

export default InvoiceTable;
